<template>
  <transition name="tab">
    <div class="tab-content" v-if="isSelected">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>

.tab-content{
  //padding: 35px 0 0 0;
}

.tab-enter-active,
.tab-leave-active {
  transition: opacity ease;
}

.tab-enter-from,
.tab-leave-to {
  opacity: 0;
}
</style>
